
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  ref,
} from "vue";
import { MBDatatablePlus } from "magicbean-saas-common";
import { useRoute } from "vue-router";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import _ from "lodash";
import { CommonHtmlType } from "@/core/directive/type/common";
import { ApiOrderRequest } from "@/core/api";
import {
  commonChangeDefaultDate,
  modalShowListener,
} from "@/core/directive/function/common";
import { OrderRequestOverviewRelationItem } from "@/core/directive/interface/orderRequest";
import ProductInfo from "@/components/layout/ProductInfo.vue";
import SkuInfo from "@/components/layout/SkuInfo.vue";

export default defineComponent({
  name: "order-request-update-product-modal",
  props: {
    merchantId: {
      type: Number,
      default: 0,
    },
    channelId: {
      type: Number,
      default: 0,
    },
    merchantChannl: {
      type: String,
      default: "",
    },
  },
  components: { MBDatatablePlus, ProductInfo, SkuInfo },
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const route = useRoute();
    const loading = ref<boolean>(false);
    const updateProductRef = ref<CommonHtmlType>(null);
    const formRef = ref<null | HTMLFormElement>(null);
    const inputSearch = ref();
    const submitButtonRef = ref<Array<HTMLElement | null>>([]);

    const submitButton = (el) => {
      submitButtonRef.value.push(el);
    };

    const setSubmitAttribute = (flag = true) => {
      if (!submitButtonRef.value) return;
      submitButtonRef.value.map((el) => {
        if (flag) {
          el?.setAttribute("data-kt-indicator", "on");
        } else {
          el?.removeAttribute("data-kt-indicator");
        }
      });
    };

    const formData = ref({
      tableHeader: [
        {
          name: t(""),
          key: "request_check",
          sortable: true,
        },
        {
          name: t(""),
          key: "request_product",
          sortable: false,
        },
        {
          name: t(""),
          key: "request_skuinfo",
          sortable: false,
        },
      ],
      search: "",
      currentPage: 1,
      pageSize: 10,
      total: 0,
      ids: [] as string[],
      products: [] as OrderRequestOverviewRelationItem[],
      items: [] as OrderRequestOverviewRelationItem[],
    });

    const rules = ref({
      items: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const options = ref({
      productLoading: false,
    });

    const toggleItemSelect = (item: OrderRequestOverviewRelationItem) => {
      if (formData.value.ids.indexOf(item.sku_id) === -1) {
        formData.value.ids.push(item.sku_id);
        formData.value.products.push(item);
      } else {
        formData.value.ids.splice(formData.value.ids.indexOf(item.sku_id), 1);
        formData.value.products.splice(
          formData.value.ids.indexOf(item.sku_id),
          1
        );
      }
    };

    const isChecked = computed(() => {
      return (item) => {
        let flag = false;
        if (formData.value.ids.indexOf(item.sku_id) > -1) {
          flag = true;
        }
        return flag;
      };
    });

    const getProduct = async () => {
      options.value.productLoading = true;
      const { data } = await ApiOrderRequest.orderRequestV2SearchSku({
        // merchant_id: props.merchantId,
        // channel_id: props.channelId,
        keyword: formData.value.search,
        page: formData.value.currentPage,
        page_size: formData.value.pageSize,
      });
      options.value.productLoading = false;
      if (data.code == 0) {
        formData.value.items.splice(
          0,
          formData.value.items.length,
          ...data.data.skus
        );
        formData.value.total = data.data.total;
      } else {
        showServerErrorMsg(data);
      }
    };

    const updateList = () => {
      getProduct();
    };
    const onCurrentPageChange = (page: number) => {
      // if (formData.value.search.trim() === "") {
      //   formData.value.currentPage = 1;
      //   formData.value.items.splice(0, formData.value.items.length, ...[]);
      //   formData.value.total = 0;
      // } else {
      formData.value.currentPage = page;
      getProduct();
      // }
    };

    const onRowsPerPageChange = (pagesize: number) => {
      // if (formData.value.search.trim() === "") {
      //   formData.value.currentPage = 1;
      //   formData.value.items.splice(0, formData.value.items.length, ...[]);
      //   formData.value.total = 0;
      // } else {
      formData.value.pageSize = pagesize;
      getProduct();
      // }
    };

    const debounceSearch = _.debounce(getProduct, 1000);

    const searchItems = () => {
      if (formData.value.search.trim() === "") {
        formData.value.currentPage = 1;
        formData.value.items.splice(0, formData.value.items.length, ...[]);
        formData.value.total = 0;
      } else {
        formData.value.currentPage = 1;
        debounceSearch();
      }
    };

    const removeSearch = () => {
      formData.value.currentPage = 1;
      formData.value.search = "";
      // updateList();
      formData.value.items.splice(0, formData.value.items.length, ...[]);
      formData.value.total = 0;
    };

    const getFormInfo = () => {
      loading.value = true;
      setSubmitAttribute();
      Promise.all([getProduct()])
        .then(() => {
          loading.value = false;
          setSubmitAttribute(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      const data = formData.value.products;
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid: boolean) => {
        if (valid) {
          hideModal(updateProductRef.value);
          emit("update-list", data);
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const handleDiscard = () => {
      hideModal(updateProductRef.value);
    };

    const resetForm = () => {
      // formRef.value?.resetFields();
      emit("reset-form");
      formData.value.ids = [];
      formData.value.products = [];
      formData.value.search = "";
      formData.value.currentPage = 1;
    };

    const init = () => {
      setTimeout(() => {
        getFormInfo();
      }, 0);
    };

    const resizeChange = () => {
      if (
        document.activeElement!.tagName == "INPUT" ||
        document.activeElement!.tagName == "TEXTAREA"
      ) {
        setTimeout(function () {
          document.activeElement!.scrollIntoView();
        }, 100);
      }
    };

    onMounted(() => {
      modalShowListener(updateProductRef.value, () => {
        // init();
        window.addEventListener("resize", resizeChange);
      });
      modalHideListener(updateProductRef.value, () => {
        resetForm();
        window.removeEventListener("resize", resizeChange);
      });
    });

    return {
      t,
      commonChangeDefaultDate,
      formRef,
      inputSearch,
      submitButtonRef,
      submitButton,
      updateProductRef,
      props,
      loading,
      formData,
      rules,
      options,
      toggleItemSelect,
      isChecked,
      searchItems,
      removeSearch,
      handleDiscard,
      resetForm,
      submit,
      updateList,
      onCurrentPageChange,
      onRowsPerPageChange,
    };
  },
});
